/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";

import { Select, Spin } from "antd";

import html2pdf from "html2pdf.js";

import { useSelector, useDispatch } from "react-redux";
import { FilterWrapper, SegmentedCustom } from "./styled";
import { linkApi, QUARTER_LENGTH, SCREEN_DEFAULT } from "../../common/ngok";

import {
  storeSetCitiesData,
  storeSetCurrentQuarter,
  storeSetHostpitalData,
  storeSethospitalSelected,
  storeSetCitySelected,
} from "../../store/data-reducer";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { storeSetCurrentDepartment, storeSetTab } from "../../store/document-reducer";
import Loading from "../../components/common/Loading/Loading";
import moment from "moment";
import { getListQuanter } from "../../helpers/getListQuanter";
import { removeAccents, removeVietnameseTones } from "../../helpers/convertVie";
import { sendGet } from "../../api/axios";
import { BtnAddUser } from "../../components/UserTable/styled";
import { EDepartment } from "../../common/const";

const FilterComponent = ({ disabled, setModalData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const patch = location?.pathname || "/dashboard";

  const language = useSelector((state) => state?.auth?.language);
  const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
  const hospitalSelected = useSelector((state) => state?.data?.hospitalSelected);
  const citySelected = useSelector((state) => state.data.citySelected);
  const dashboardData = useSelector((state) => state?.data?.dashboardData) || null;
  const currentQuarter = useSelector((state) => state?.data?.currentQuarter) || 0;
  const overviewCountry = useSelector((state) => state.data.overviewCountry);
  const overviewCity = useSelector((state) => state.data.overviewCity);

  const citiesData = useSelector((state) => state.data.citiesData);
  const hostPitals = useSelector((state) => state.data.hostPitals);
  const tabDocument = useSelector((state) => state.document.tab);
  const currentDepartment = useSelector((state) => state.document.currentDepartment);

  const listQuater = useMemo(() => {
    if (overviewCity) return overviewCity?.map((item) => item?.time) || [];

    if (overviewCountry) return overviewCountry?.map((item) => item?.time) || [];

    if (dashboardData) return dashboardData?.map((item) => item?.time) || [];

    return [];
  }, [overviewCountry, overviewCity, dashboardData]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const defaultCity = useMemo(() => {
    return citySelected?.name || "";
  }, [hospitalSelected, citiesData]);
  const exportPdfData = async () => {
    const drafDeparment = currentDepartment;
    await dispatch(storeSetCurrentDepartment(3));
    setIsLoadingScreen(true);
    await setTimeout(() => {
      const element = document.getElementById("exportDagta");
      const element2 = document.getElementById("exportDagta2");
      const time = listQuater[currentQuarter].replace("/", "_");
      const hostpitalName = removeAccents(hospitalSelected?.name?.replaceAll(" ", "_")) || "";
      const timeStamp = new Date().getTime();
      const fileName = `KQKS_SanKhoa_${hostpitalName}_${time}_${timeStamp}.pdf`;
      const fileName2 = `KQKS_NhiKhoa_${hostpitalName}_${time}_${timeStamp}.pdf`;
      const opt = {
        margin: 0,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        pagebreak: { mode: ["css"] },
      };
      html2pdf().set(opt);
      html2pdf()
        .from(element)
        .save(fileName)
        .then(() => {
          setTimeout(() => {
            setIsLoadingScreen(false);
            dispatch(storeSetCurrentDepartment(drafDeparment));
          }, 500);
        });
      html2pdf().from(element2).save(fileName2);
    }, 500);
  };

  const getCities = async () => {
    const myHeaders = new Headers({
      Authorization: "Token " + user?.token,
      "Content-Type": "application/x-www-form-urlencoded",
    });
    fetch(`${linkApi}/dm/data/province?info=all`, {
      method: "GET",
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((data) => {
        const reDataCity = data?.provinces;
        reDataCity.unshift({
          name: t("common.none"),
          code: -1,
        });
        dispatch(storeSetCitiesData(reDataCity || []));
      });
  };

  const getHostPital = async (code) => {
    setIsLoading(true);
    const myHeaders = new Headers({
      Authorization: "Token " + user?.token,
      "Content-Type": "application/x-www-form-urlencoded",
    });
    fetch(`${linkApi}/dm/data/province?code=${code}&info=hospitals`, {
      method: "POST",
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((data) => {
        const reDataCity = data?.hospitals;
        reDataCity.unshift({
          name: t("common.none"),
          code: -1,
        });
        dispatch(storeSetHostpitalData(reDataCity || []));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const exportRaw = async () => {
    try {
      setIsLoadingScreen(true);
      const response = await sendGet(`/dm/data/raw/export?lang=${language}`);
      if (response?.status === "successful") {
        setTimeout(() => {
          window.open(`${linkApi}/${response?.data?.url}`);
        }, 0);
      }
    } catch (error) {
    } finally {
      setIsLoadingScreen(false);
    }
  };

  useEffect(() => {
    getCities();
  }, []);

  return (
    <FilterWrapper>
      {isLoadingScreen && <Loading />}
      <div className="adress">
        {patch === SCREEN_DEFAULT[6] && (
          <div className="back" onClick={() => navigate(`${SCREEN_DEFAULT[1]}`)}>
            {t("filter.back")}
          </div>
        )}
        {!disabled || (patch === SCREEN_DEFAULT[2] && user?.is_superuser === "True") ? (
          <span>{t("filter.city")}</span>
        ) : (
          <div />
        )}
        {!disabled || (patch === SCREEN_DEFAULT[2] && user?.is_superuser === "True") ? (
          <Select
            defaultValue={defaultCity || t("common.none")}
            value={citySelected?.name || t("common.none")}
            className="select-city"
            showSearch
            filterOption={(input, option) =>
              removeVietnameseTones((option?.label ?? "").toLowerCase()).includes(
                removeVietnameseTones(input.toLowerCase())
              )
            }
            options={citiesData?.map((element, index) => {
              return {
                ...element,
                value: index,
                label: element?.name,
              };
            })}
            onChange={(e) => {
              getHostPital(citiesData[e].code);
              dispatch(storeSetHostpitalData([]));
              dispatch(storeSethospitalSelected(null));
              dispatch(storeSetCitySelected(citiesData[e]));
            }}
          ></Select>
        ) : (
          <div />
        )}
        {!disabled && <span className="hostpital">{t("filter.hospital")}</span>}
        {!disabled && (
          <Select
            defaultValue={hospitalSelected?.name || t("common.none")}
            value={hospitalSelected?.name || t("common.none")}
            className="select-hostpital"
            onChange={(e) => {
              dispatch(storeSethospitalSelected(hostPitals[e]));
            }}
            disabled={disabled}
          >
            {isLoading ? (
              <Select.Option>
                <Spin />
              </Select.Option>
            ) : (
              <>
                {hostPitals?.map((element, index) => {
                  return (
                    <Select.Option key={String(index)}>
                      {index + 1}. {element?.name}
                    </Select.Option>
                  );
                })}
              </>
            )}
          </Select>
        )}
        {(!disabled || !hospitalSelected || patch === SCREEN_DEFAULT[2]) &&
        patch !== SCREEN_DEFAULT[4] &&
        patch !== SCREEN_DEFAULT[3] &&
        patch !== SCREEN_DEFAULT[7] ? (
          <div>
            <span className="hostpital">{t("filter.quarter")}</span>
            {listQuater?.length > 0 && (
              <Select
                defaultValue={listQuater[currentQuarter]}
                className="select-quarter"
                onChange={(e) => {
                  dispatch(storeSetCurrentQuarter(e));
                }}
              >
                {listQuater?.map((element, index) => {
                  return <Select.Option key={String(index)}>{element}</Select.Option>;
                })}
              </Select>
            )}
          </div>
        ) : null}
        {/* {patch === SCREEN_DEFAULT[3] && <RangePicker className="datePicker" />} */}
        {patch === SCREEN_DEFAULT[4] && (
          <SegmentedCustom
            options={[
              { label: t("document.decisionInstruction"), value: 1 },
              { label: t("document.awardedHospitals"), value: 2 },
            ]}
            value={tabDocument}
            onChange={(e) => {
              dispatch(storeSetTab(e));
            }}
            size="large"
          />
        )}
        {patch === SCREEN_DEFAULT[6] && (
          <SegmentedCustom
            options={[
              {
                label: t("dashBoard.obstetricDept"),
                value: EDepartment.OBSTETRIC,
              },
              {
                label: t("dashBoard.pediatricDept"),
                value: EDepartment.PEDIATRIC,
              },
            ]}
            value={currentDepartment}
            onChange={(e) => {
              dispatch(storeSetCurrentDepartment(e));
            }}
            size="large"
          />
        )}
      </div>
      {patch === SCREEN_DEFAULT[7] && (
        <BtnAddUser onClick={() => setModalData(true)}>{t("userManagement.addUser")}</BtnAddUser>
      )}
      {(patch === SCREEN_DEFAULT[1] || patch === "/") &&
      hospitalSelected &&
      dashboardData &&
      hospitalSelected.code !== -1 &&
      citySelected.code !== -1 ? (
        <div className="export" onClick={() => navigate(`${SCREEN_DEFAULT[6]}`)}>
          {t("filter.generateReport")}
        </div>
      ) : null}

      {patch === SCREEN_DEFAULT[6] && (
        <div className="export" onClick={exportPdfData}>
          {t("filter.exportReport")}
        </div>
      )}
      {patch === SCREEN_DEFAULT[3] && (
        <div className="export" onClick={exportRaw}>
          {t("rawData.export")}
        </div>
      )}
    </FilterWrapper>
  );
};

export default FilterComponent;
