import React, { useMemo } from "react";
import { BornWrapper } from "./styled";
import accept from "../../assets/born/accept.png";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LinePoint } from "../../components/common/line-chart/LinePoint";

const LastDataDisplay = ({ department, index, elementData }) => {
  if (department === "SK" && (index === 6 || index === 7)) {
    const perPoint = elementData?.PER === "N/A" ? 0 : elementData?.PER || 0;
    return (
      <div className="w-20">
        {perPoint} {"%"}
      </div>
    );
  }

  return (
    <div className="w-20">
      {elementData?.ST || "0"}
      {elementData?.ST !== "N/A" ? "%" : ""}
    </div>
  );
};

const RowComponent = ({ obstetric, index, elementData, elementST, elementSM, timeLine, department }) => {

  return (
    <div className="row" key={obstetric.criteria}>
      <div className="stt">{index + 1}</div>
      <div className="criteria">{obstetric.criteria}</div>
      <div className="chart">
        <div className="container-chart">
          <LinePoint
            hiddenCaesarean={department === "NK"}
            dataST={elementST}
            dataSM={elementSM}
            time={timeLine}
            department={index >= 6 ? "NK" : department}
            passLevelST={obstetric.STRate}
            passLevelSM={obstetric.SMRate}
          />
        </div>
      </div>
      {department === "SK" && 6 > index ? (
        <>
          <div className="w-10">
            {elementData?.ST || "0"}
            {elementData?.ST !== "N/A" ? "%" : ""}
          </div>
          <div className="w-10">
            {elementData?.SM || "0"}
            {elementData?.SM !== "N/A" ? "%" : ""}
          </div>
        </>
      ) : (
        <LastDataDisplay department={department} index={index} elementData={elementData} />
      )}
      <div className="pass border-none">{elementData?.result === "passed" ? <img alt="" src={accept} /> : <div />}</div>
    </div>
  );
};

const BornComponent = ({ data, isGeneral, dataList, department }) => {
  const { t } = useTranslation();
  const currentQuarter = useSelector((state) => state?.data?.currentQuarter);
  const dashboardData = useSelector((state) => state?.data?.dashboardData);

  const timeLine = useMemo(() => {
    return dataList?.map((element) => element?.time) || [];
  }, [dataList]);

  const dataMainSK = useMemo(() => {
    return dataList?.[currentQuarter]?.data?.[department] || [];
  }, [dataList, currentQuarter, department]);

  const lineChartDataST = useMemo(() => {
    return data?.map((_, index) =>
      dataList?.map((dataElement) => {
        const point = dataElement?.data[department][index + 1]?.values?.ST;
        if (department === "SK" && (index === 6 || index === 7)) {
          const perPoint = dataElement?.data[department][index + 1]?.values?.PER;
          return perPoint === "N/A" ? 0 : perPoint;
        }
        return point === "N/A" ? null : point;
      })
    );
  }, [data, dataList, department]);

  const lineChartDataSM = useMemo(() => {
    return data?.map((_, index) =>
      dataList?.map((dataElement) => {
        const point = dataElement?.data[department][index + 1]?.values?.SM;
        return point === "N/A" ? null : point;
      })
    );
  }, [data, dataList, department]);

  const checkSuccess = useMemo(() => {
    if (!dashboardData) return null;

    const dataExport = department === "SK" ? [1, 2, 3, 4, 5, 6, 7, 8] : [1, 2, 3, 4, 5, 6, 7];
    const arr = dataExport.map((_, index) => {
      if (
        index === 0 &&
        department === "NK" &&
        (dashboardData[currentQuarter]?.data[department][index + 1]?.values?.ST === 0 ||
          dashboardData[currentQuarter]?.data[department][index + 1]?.values?.ST === "N/A")
      ) {
        return "passed";
      }
      return dashboardData[currentQuarter]?.data[department][index + 1]?.values?.result || null;
    });

    return !arr.find((element) => element !== "passed");
  }, [department, dashboardData, currentQuarter]);

  return (
    <BornWrapper>
      <div className="row sticky">
        <div className="stt font-bold">{t("exportData.index")}</div>
        <div className="criteria font-bold">{t("born.criteria")}</div>
        <div className="chart font-bold">{t("born.chart")}</div>
        {department === "SK" ? (
          <>
            <div className="w-10 font-bold">{isGeneral ? t("born.postpartumMother") : t("born.vaginalDelivery")}</div>
            <div className="w-10 font-bold">{isGeneral ? t("born.motherYoung") : t("born.Csection")}</div>
          </>
        ) : (
          <div className="w-10 font-bold w-20">{isGeneral ? t("born.postpartumMother") : t("born.parameter")}</div>
        )}
        <div className="pass font-bold border-none">{t("born.complete")}</div>
      </div>

      {dataMainSK &&
        data?.map((element, index) => (
          <RowComponent
            key={index}
            obstetric={element}
            index={index}
            elementData={dataMainSK[index + 1]?.values}
            elementSM={lineChartDataSM[index]}
            elementST={lineChartDataST[index]}
            timeLine={timeLine}
            department={department}
            data={data}
          />
        ))}

      <div className="row">
        <div className="stt"></div>
        <div className="criteria">{t("export.conclusion")}</div>
        <div className="chart">{t("export.conclusionContent")}</div>
        <div className="w-20"></div>
        <div className="pass border-none">{checkSuccess && <img alt="" src={accept} />}</div>
      </div>
    </BornWrapper>
  );
};

export default BornComponent;
